import React, { useParams, useRef, useState, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, useRouteMatch, NavLink, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import orange from '../images/orange-circle.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function PendingOpportunities() {
    const [selected, setSelected] = useState(0)
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [opportunities, setOpportunities] = useState([])
    const [opportunityById, setOpportunityById] = useState([])
    const [htmlName, setHtmlName] = useState('');
    const [content, setContent] = useState('');
    const [description, setDescription] = useState('');
    const refText = useRef('myTextarea');
    const [isLoading, setIsLoading] = useState(true)

    let { path, url } = useRouteMatch();
   // console.log(path)

    useEffect(() => {
        getPendingOpportunityDetails();
    }, []);

    const getPendingOpportunityDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }

        axios.get(config.userUrl + 'user/getPendingOpportunityDetails', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setOpportunities(response.data.getPendingOpportunityDetails)
                    setIsLoading(false)
                 //   console.log("getPendingOpportunityDetails", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getOpportunityById = (id) => {
       // console.log("id", id)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + `user/getOpportunityById/${id}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setOpportunityById(response.data.getOpportunityById)
                    // setIsLoading(false)
                  // console.log("res", response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const reviewOpportunity = (data, flag) => {
      //  console.log("edit", data)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "opportunityId": data,
            "status": flag,
            "description": description
        }
       // console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/acceptAndRejectOpportunityDetails', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    window.location.href = '/opportunities'
                   // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    if (!sessionStorage.getItem('junkStore')) {
        return <Redirect to='/' />;
    }
    if (isLoading) {
        return <div></div>
    }
    const updatedDate = opportunities.map((i, idx) => {
        var a = new Date(i.createdAt)
        var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return updated;
    })
    const showAccept = () => {
        document.getElementById('accept').style.display = 'flex'
    }
    const showAcceptNone = () => {
        document.getElementById('accept').style.display = 'none'
    }
    const showReject = () => {
        document.getElementById('reject').style.display = 'flex'
    }
    const showRejectNone = () => {
        document.getElementById('reject').style.display = 'none'
    }
    // let result = reviewQueue.filter(item => userTopics.some(data => item.topicName === data.topicName));
    // // console.log("result", result)
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>

                <Row style={{ width: "100%" }}>
                    <Col md={4}>
                        <div class="scrolling-area-review">
                            <div class="scrolling-element-inside">
                                <div className="inbox">Inbox</div>
                                {opportunities.length !== 0 ?
                                    <div>
                                        {opportunities.map((data, i) => (
                                            <div>
                                                <Card style={{ border: "none" }} onClick={() => getOpportunityById(data.id)}>
                                                    <NavLink to={`${path}/${data.id}`} className="inbox_Card link" activeClassName="_Active" key={i} >
                                                        <Row style={{ width: "100%" }}>
                                                            <Col md={2}>
                                                                <img src={orange} style={{ width: "32px" }} />
                                                            </Col>
                                                            <Col md={7}>
                                                                <div className="article-approved">{data.typeOfOpportunity} </div>
                                                                <div className="messages">{data.type === 1 ? "Temporary" : "Permanent"}</div>


                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="time">{updatedDate[i]}</div>
                                                            </Col>
                                                        </Row>
                                                    </NavLink>
                                                </Card>
                                                <hr /></div>
                                        ))}
                                    </div> : <p>No Opportunities to Review</p>}


                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <Card style={{ height: "85vh" }}>
                            {opportunityById.map((data, i) => (
                                <Route key={i} path={`${path}/${data.id}`} >
                                    <div>
                                        <div class="scrolling-area-review-queue">
                                            <div class="scrolling-element-inside">
                                                {/* <img src={config.userUrl + data.image} className="article-title mt-2 ml-5" style={{ width: "76%", height: "200px" }} /> */}
                                                <p className="article-title mt-2 ml-5">&nbsp;{data.typeOfOpportunity}</p>
                                                <Row style={{ width: "100%" }}>
                                                    <Col md={6}>
                                                        <div
                                                            className=" richTextFieldreview description-radius"
                                                            style={{ resize: "none", wordWrap: "break-word" }}

                                                        >
                                                            Organization Name: {data.company}<br />
                                                            Tenure: {data.tenure}<br />
                                                            Location: {data.location}<br />
                                                            Brief Job Description: {data.briefJobDescription}<br />

                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div
                                                            className=" richTextFieldreview description-radius"
                                                            style={{ resize: "none", wordWrap: "break-word" }}

                                                        >
                                                            Type: {data.type === 1 ? "Temporary" : "Permanent"} <br />
                                                            Remuneration: {data.remuneration}<br />
                                                            Vacancies: {data.vacancies}<br />
                                                            Apply By Date: {data.applyByDate.split("T")[0].split("-").reverse().join("-")}

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="ml-3 mr-3 mt-3 form-group">
                                                <textarea
                                                    rows={2}
                                                    name="description"
                                                    value={description}
                                                    placeholder="Write your comments"
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    className="web-font form-control"
                                                    style={{ resize: "none", wordWrap: "break-word", }}
                                                />
                                            </div>
                                            <div className="float_Right mr-3" >
                                                <div>
                                                    <div>{description !== "" ?
                                                        <div >
                                                    {/* <Button onClick={showAccept} className="sendreview-btn mt-1 ml-4" variant="default">Accept</Button>
                                                            <div id="accept" className="modal_new">
                                                                <form className="modal-content-log">
                                                                    <div className="md-container">
                                                                        <p className="mt-2">Do you really want to Accept?</p>
                                                                        <center><Button variant="default" onClick={() => reviewOpportunity(data.id, 1)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                            <Button variant="default" onClick={showAcceptNone} className="deletebutton">No</Button></center>
                                                                    </div>
                                                                </form>
                                                            </div> */}
                                                            <Button variant="default" onClick={showReject} className="save-btn mt-1 ml-3">Reject</Button>
                                                            <div id="reject" className="modal_new">
                                                                <form className="modal-content-log">
                                                                    <div className="md-container">
                                                                        <p className="mt-2">Do you really want to Reject?</p>
                                                                        <center><Button variant="default" onClick={() => reviewOpportunity(data.id, 3)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                            <Button variant="default" onClick={showRejectNone} className="deletebutton">No</Button></center>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div> :
                                                        <div>
                                                            {/* <Button variant="default" disabled="true" className="sendreview-btn mt-1 ml-4">Accept</Button> */}
                                                            <Button disabled="true" className="save-btn mt-1 ml-3" variant="default">Reject</Button></div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Route>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </div >
        </div >
    );
}

