import React, { Component } from "react";
import { Button, Form, Table, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../Advertisements/Advertisement.css';
import axios from 'axios';
import moment from 'moment-timezone'
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class AllIncidents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            incidents: [],
            redirect: false,
            isLoading: true
        }
    }
    componentDidMount() {
        // console.log("offers", this.state.offers)
        this.getAllIncidents();
    }


    getAllIncidents = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getAllIncidents', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        incidents: response.data.result,
                        isLoading: false
                    })
                    //  console.log("getPublishedgames", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, incidents } = this.state
        // const createdDate = incidents.map((i, idx) => {
        //     var a = new Date(i.createdAt)
        //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        //     return updated;
        // })
        // const publishedDate = incidents.map((i, idx) => {
        //     var end = new Date(i?.resolutionDate)
        //     var updatedEndDate = end.getDate() + "-" + parseInt(end.getMonth() + 1) + "-" + end.getFullYear();
        //     return updatedEndDate;
        // })
        // if (isLoading) {
        //     return <div className="App"></div>;
        // }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >

                    <div >
                        {/* <div> <Link to="/incidents/updateincident"><Button variant="default" className="createadv-btn">+ &nbsp;Review</Button></Link>
                        </div> */}
                        <div className="mt-3 active-add-h-text">Incidents</div>
                        {incidents.length === 0 ? <center><h4>No Incidents</h4></center> :
                            <div class="mt-3 tableFixHead" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr>
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="th-heading" style={{ width: "10%" }}>Username</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Title</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Description</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Reason</th>
                                            <th scope="col" className="th-heading" style={{ width: "12%" }}>Status</th>
                                            <th scope="col" className="th-heading" style={{ width: "12%" }}>Created At</th>
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Resolution Date</th>
                                            <th ></th>
                                            <th ></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {incidents.map((data, i) => (
                                            <tr className={i / 2 === 0 ? "" : "green"}>
                                                <td className="td-text" >&nbsp;&nbsp;{i + 1}</td>
                                                <td className="td-text">{data.name}</td>
                                                <td className="td-text">{data.title}</td>
                                                <td className="td-text">{data.description}</td>
                                                <td className="td-text">{data.reason}</td>
                                                <td className="td-text">{data.status == 1 ? "Active" : data.status == 2 ? "Acknowledged" : data.status == 3 ? "Closed" : "Rejected"}</td>
                                                <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                                <td className="td-text">{data.resolutionDate ? moment(data.resolutionDate).format("DD-MM-YYYY") : ""}</td>
                                                <td className="cursor"><Link to={`/incidents/viewincident/${data.id}`} className="poll-link"> <i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                                                <td className="cursor" >{data.status == "1" || data.status == "2" ? <Link to={`/incidents/updateincident/${data.id}`} className="poll-link"> <i class="fa fa-pencil left" aria-hidden="true"></i></Link> : ""} </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>


                </div>
            </div >
        );
    };
}

