import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import moment from 'moment-timezone';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ViewIncident(props) {
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [reason, setReason] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("")
    const [createdAt, setCreatedAt] = useState("")
    const [incidentId, setIncidentId] = useState()
    const [name, setName] = useState("")
    const [resolutionDate, setResolutionDate] = useState()
    const [redirect, setRedirect] = useState(false)
    const [rootCauseAnalysis, setRootCauseAnalysis] = useState()
    const [correctiveActions, setCorrectiveActions] = useState()
    const [serviceLevelAgreementCompliance, setServiceLevelAgreementCompliance] = useState()
    const [resolutionTime, setResolutionTime] = useState()
    const [firstResponseTime, setFirstResponseTime] = useState()
    const [escalation, setEscalation] = useState("")
    const [financial, setFinancial] = useState("")
    const [customer, setCustomer] = useState("")
    const [escalationLevel, setEscalationLevel] = useState()
    const [financialImpact, setFinancialImpact] = useState()
    const [customerImpact, setCustomerImpact] = useState()
    const [internalNotes, setInternalNotes] = useState()
    const [externalNotes, setExternalNotes] = useState()


    useEffect(() => {
        getIncidentById()
    }, []);
    const getIncidentById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + `user/getIncidentById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    let data = response.data.incident[0];
                    setTitle(data?.title)
                    setDescription(data?.description)
                    setCreatedAt(data?.createdAt)
                    setStatus(data?.status)
                    setIncidentId(data?.id)
                    setResolutionDate(data?.resolutionDate)
                    setName(data?.name)
                    setRootCauseAnalysis(data?.rootCauseAnalysis)
                    setCorrectiveActions(data?.correctiveActions)
                    setServiceLevelAgreementCompliance(data?.serviceLevelAgreementCompliance)
                    setFirstResponseTime(data?.firstResponseTime)
                    setResolutionTime(data?.resolutionTime)
                    setInternalNotes(data?.internalNotes)
                    setExternalNotes(data?.externalNotes)
                    setEscalation(data?.escalation)
                    setEscalationLevel(data?.escalationLevel)
                    setFinancial(data?.financial)
                    setFinancialImpact(data?.financialImpact)
                    setCustomer(data?.customer)
                    setCustomerImpact(data?.customerImpact)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    if (redirect) {
        return <Redirect to="/games" />
    }

    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                <h5>View Incident</h5>
                <Form>
                    <Row style={{ width: "74%" }}>
                        <Col md={6}>
                            <div className="poll-date">Username</div>
                            <div className="form-group">
                                <input className="web-font form-control" name="name"
                                    placeholder="Name"
                                    value={name}
                                    readOnly />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="poll-date">Created At</div>
                            <div className="form-group">
                                <input className="web-font form-control" name="createdAt"
                                    placeholder="Created At"
                                    value={moment(createdAt).format("DD-MM-YYYY")}
                                    readOnly />
                            </div>
                        </Col>
                    </Row>
                    <div className="poll-date">Title</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <input className="web-font form-control" name="title"
                            placeholder="Title"
                            value={title}
                            readOnly />
                    </div>
                    <div className="poll-date">Description</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="description"
                            placeholder="Description"
                            value={description}
                            rows={3}
                            style={{ resize: "none" }}
                            readOnly />
                    </div>

                    <div className="poll-date">Resolution Details</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="reason"
                            placeholder="Resolution Details"
                            rows={3}
                            style={{ resize: "none" }}
                            value={reason}
                            readOnly
                        />
                    </div>
                    <div className="poll-date">Root Cause Analysis</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="rootCauseAnalysis"
                            placeholder="Root Cause Analysis"
                            rows={3}
                            style={{ resize: "none" }}
                            value={rootCauseAnalysis}
                            readOnly
                        />
                    </div>
                    <div className="poll-date">Corrective Actions</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <textarea className="web-font form-control" name="correctiveActions"
                            placeholder="Corrective Actions" rows={3}
                            style={{ resize: "none" }}
                            value={correctiveActions}
                            readOnly />
                    </div>
                    <div className="poll-date">Internal Notes</div>
                    <div className="form-group">
                        <textarea rows={3} style={{ resize: "none" }}
                            className="web-font form-control" name="internalNotes"
                            placeholder="Internal Notes"
                            value={internalNotes}
                            readOnly />
                    </div>
                    <div className="poll-date">External Notes</div>
                    <div className="form-group">
                        <textarea rows={3} style={{ resize: "none" }}
                            className="web-font form-control" name="externalNotes"
                            placeholder="External Notes"
                            value={externalNotes}
                            readOnly />
                    </div>
                    <div className="poll-date">First Response Time</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <input className="web-font form-control" name="firstResponseTime"
                            placeholder="First Response Time"
                            value={firstResponseTime}
                            readOnly />
                    </div>
                    <div className="poll-date">Resolution Time</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <input className="web-font form-control" name="resolutionTime"
                            placeholder="Resolution Time"
                            value={resolutionTime}
                            readOnly />
                    </div>
                    <div className="poll-date">Internal Notes</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <input className="web-font form-control" name="internalNotes"
                            placeholder="Internal Notes"
                            value={internalNotes}
                            readOnly />
                    </div>
                    <div className="poll-date">External Notes</div>
                    <div className="form-group" style={{ width: "71.5%" }}>
                        <input className="web-font form-control" name="externalNotes"
                            placeholder="External Notes"
                            value={externalNotes}
                            readOnly />
                    </div>
                    <Row style={{ width: "74%" }} className="mt-2">
                        <Col md={6}>
                            <div className="poll-date">Escalation Level</div>
                            <div className="ml-1 form-check form-check-inline form_check" style={{ display: "flex" }}>
                                <input name="escalation" disabled="true" className="form-check" type="radio" value="No" checked={escalation === "No"} onChange={(e) => setEscalation("No")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    No
                                </label>
                                <input name="escalation" disabled="true" className="ml-2 form-check" type="radio" value="Low" checked={escalation === "Low"} onChange={(e) => setEscalation("Low")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    Low
                                </label>
                                <input name="escalation" disabled="true" className="ml-2 form-check" type="radio" value="Medium" checked={escalation === "Medium"} onChange={(e) => setEscalation("Medium")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    Medium
                                </label>
                                <input name="escalation" disabled="true" className="ml-2 form-check" type="radio" value="High" checked={escalation === "High"} onChange={(e) => setEscalation("High")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    High
                                </label>
                            </div>
                        </Col>
                        <Col md={6}>
                            {escalation == "" ?
                                <>
                                </> : escalation !== "No" ?
                                    <>
                                        <div className="mt-2 form-group">
                                            <input className="web-font form-control" name="escalationLevel"
                                                placeholder="Escalation Level"
                                                value={escalationLevel}
                                                readOnly />
                                        </div>
                                    </> : ""}

                        </Col>
                    </Row>
                    <Row style={{ width: "74%" }} className="mt-2">
                        <Col md={6}>
                            <div className="poll-date">Financial Impact</div>
                            <div className="ml-1 form-check form-check-inline form_check" style={{ display: "flex" }}>
                                <input name="financial" disabled="true" className="form-check" type="radio" value="No" checked={financial === "No"} onChange={(e) => setFinancial("No")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    No
                                </label>
                                <input name="financial" disabled="true" className="ml-2 form-check" type="radio" value="Low" checked={financial === "Low"} onChange={(e) => setFinancial("Low")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    Low
                                </label>
                                <input name="financial" disabled="true" className="ml-2 form-check" type="radio" value="Medium" checked={financial === "Medium"} onChange={(e) => setFinancial("Medium")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    Medium
                                </label>
                                <input name="financial" disabled="true" className="ml-2 form-check" type="radio" value="High" checked={financial === "High"} onChange={(e) => setFinancial("High")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    High
                                </label>
                            </div>
                        </Col>
                        <Col md={6}>
                            {financial == "" ?
                                <>
                                </> : financial !== "No" ?
                                    <>
                                        <div className="mt-2 form-group">
                                            <input className="web-font form-control" name="financialImpact"
                                                placeholder="Financial Impact"
                                                value={financialImpact}
                                                readOnly />
                                        </div>
                                    </> : ""}

                        </Col>
                    </Row>
                    <Row style={{ width: "74%" }} className="mt-2">
                        <Col md={6}>
                            <div className="poll-date">Customer Impact</div>
                            <div className="ml-1 form-check form-check-inline form_check" style={{ display: "flex" }}>
                                <input name="customer" disabled="true" className="form-check" type="radio" value="No" checked={customer === "No"} onChange={(e) => setCustomer("No")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    No
                                </label>
                                <input name="customer" disabled="true" className="ml-2 form-check" type="radio" value="Low" checked={customer === "Low"} onChange={(e) => setCustomer("Low")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    Low
                                </label>
                                <input name="customer" disabled="true" className="ml-2 form-check" type="radio" value="Medium" checked={customer === "Medium"} onChange={(e) => setCustomer("Medium")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    Medium
                                </label>
                                <input name="customer" disabled="true" className="ml-2 form-check" type="radio" value="High" checked={customer === "High"} onChange={(e) => setCustomer("High")} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    High
                                </label>
                            </div>
                        </Col>
                        <Col md={6}>
                            {customer == "" ?
                                <>
                                </> : customer !== "No" ?
                                    <>
                                        <div className="mt-2 form-group">
                                            <input className="web-font form-control" name="customerImpact"
                                                placeholder="Customer Impact"
                                                value={customerImpact}
                                                readOnly />
                                        </div>
                                    </> : ""}

                        </Col>
                    </Row>
                    <Row style={{ width: "74%" }} className="mt-2">
                        <Col md={6}>
                            <div className="poll-date">Service Level Agreement (Compliance)</div>
                            <div className="ml-1 form-check form-check-inline form_check" style={{ display: "flex" }}>
                                <input name="serviceLevelAgreementCompliance" className="form-check" type="radio" value="Met" checked={serviceLevelAgreementCompliance === "Met"} readOnly />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    Met
                                </label>
                                <input name="serviceLevelAgreementCompliance" className="ml-2 form-check" disabled="true" type="radio" value="Not Met" checked={serviceLevelAgreementCompliance === "Not Met"} readOnly />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    Not Met
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ width: "74%" }}>
                        <Col md={6}>
                            <div className="mt-2 poll-date">Status</div>
                            <div className="form-group" style={{ width: "71.5%" }}>
                                <input className="web-font form-control" name="status"
                                    placeholder="Status"
                                    value={status == 1 ? "Active" : status == 2 ? "Closed" : "Rejected"}
                                    readOnly />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="poll-date">Resolution Date</div>
                            <input
                                className="web-font form-control form-bg"
                                value={resolutionDate ? moment(resolutionDate).format("DD-MM-YYYY") : ""}
                                readOnly
                            />
                        </Col>
                    </Row>
                    <Row style={{ width: "74%" }} className="mt-3">
                        <Col md={6}>
                            <div className="poll-date">First Response Time</div>
                            <div className="form-group">
                                <input className="web-font form-control" name="firstResponseTime"
                                    placeholder="First Response Time (hrs)"
                                    value={firstResponseTime}
                                    readOnly />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="poll-date">Resolution Time</div>
                            <div className="form-group">
                                <input className="web-font form-control" name="resolutionTime"
                                    placeholder="Resolution Time (hrs)"
                                    value={resolutionTime}
                                    readOnly />
                            </div>
                        </Col>
                    </Row>


                    <Link to="/incidents" className="float_right_poll"><Button variant="default" className="cancelreview-btn mt-2">Back</Button></Link>

                </Form>
            </div >
        </div >
    );
};
