import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import adv from '../images/advertisement.png';
import './Advertisement.css';
import axios from 'axios';
import fileupload from '../images/file-upload-svgrepo-com.svg';
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validPhoneNoRegex = RegExp(
    /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/i
);
export default class CreateCampaign extends Component {
    constructor(props) {
        super(props);
        var today = new Date();
        var currentDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);
        this.imgRef = React.createRef();
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            advTypes: [],
            image: "",
            campaignName: "",
            type: "",
            description: "",
            startDate: "",
            timingType: "",
            clicks: "",
            views: "",
            endDate: "",
            campaignUrl: "",
            imageUrl: "",
            bannerImg: "",
            advType: "",
            image: "",
            campaignPaymentType: "",
            advertiserName: "",
            advertiserEmail: "",
            advertiserNumber: "",
            designation: "",
            designationError: '',
            paidOrFree: "",
            payment: "",
            campaignNameError: '',
            addError: '',
            descriptionError: '',
            startDateError: '',
            endDateError: '',
            advTypeError: '',
            nameError: '',
            emailError: '',
            designationError: '',
            phoneError: '',
            currentDate: currentDate,
            redirect: false

        }
        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateCampaignName = this.validateCampaignName.bind(this);
        this.validateTypeOfAdvertisement = this.validateTypeOfAdvertisement.bind(this);
        this.validateDescription = this.validateDescription.bind(this);
        this.validateStartDate = this.validateStartDate.bind(this);
        this.validateEndDate = this.validateEndDate.bind(this);
        this.validateName = this.validateName.bind(this);
        this.validateAdvType = this.validateAdvType.bind(this);
        this.validateEmailAddress = this.validateEmailAddress.bind(this);
        this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
        this.validateDesignation = this.validateDesignation.bind(this);
        this.validateField = this.validateField.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    fileHandler = (e) => {
        this.setState({ image: (e.target.files[0]) })
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    changeStartDate(date) {
        if (date.toDateString() === new Date().toDateString()) {
            this.setState({ dateErr: "You cannot select today" })
        } else {
            //console.log("start", date.toDateString(), "new", new Date().toDateString())
            this.setState({
                startDate: date,
                dateErr: ""
            })
        }
    }
    changeEndDate(date) {
        this.setState({ endDate: date })
    }
    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }
    componentDidMount() {
        // console.log(this.state.currentDate)
        this.getAllAdvertismentTypes();
    }
    getAllAdvertismentTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllAdvertismentTypes', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({ advTypes: response.data.advTypes })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    handleSubmit(event) {
        event.preventDefault();
        // console.log(this.state);
        let formFileds = [
            "campaignName",
            "type",
            "description",
            "startDate",
            "endDate",
            "advType",
            "advertiserName",
            "advertiserEmail",
            "advertiserNumber",
            "designation"
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        if (!isValid) {
            alert("Please enter the details")
        }
    }

    validateField(name) {
        let isValid = false;
        if (name === "campaignName") isValid = this.validateCampaignName();
        if (name === "type") isValid = this.validateTypeOfAdvertisement();
        if (name === "description") isValid = this.validateDescription();
        if (name === "advertiserName") isValid = this.validateName();
        if (name === "advType") isValid = this.validateAdvType();
        else if (name === "advertiserEmail") isValid = this.validateEmailAddress();
        else if (name === "advertiserNumber") isValid = this.validatePhoneNumber();
        else if (name === "designation") isValid = this.validateDesignation();
        return isValid;
    }
    validateCampaignName() {
        let campaignNameError = "";
        const value = this.state.campaignName;
        if (value.trim() === "") campaignNameError = "Campaign Name is required";
        this.setState({
            campaignNameError
        });
        return campaignNameError === "";
    }
    validateTypeOfAdvertisement() {
        let addError = "";
        const value = this.state.type;
        if (value.trim() === "") addError = "Type of Advertisement is required";
        this.setState({
            addError
        });
        return addError === "";
    }
    validateDescription() {
        let descriptionError = "";
        const value = this.state.description;
        if (value.trim() === "") descriptionError = "Description is required";
        this.setState({
            descriptionError
        });
        return descriptionError === "";
    }
    validateStartDate() {
        let startDateError = "";
        if (this.state.startDate >= this.state.currentDate)
            startDateError = "Start date should be less than end date";
        this.setState({
            startDateError
        });
        return startDateError === "";
    }
    validateEndDate() {
        let endDateError = "";
        if (this.state.startDate > this.state.endDate)
            endDateError = "End date should be greater than start date";
        this.setState({
            endDateError
        });
        return endDateError === "";
    }

    validateName() {
        let nameError = "";
        const value = this.state.advertiserName;
        if (value.trim() === "") nameError = "Name is required";
        else if (value.length < 5)
            nameError = 'Name must be at least 5 characters long!';
        this.setState({
            nameError
        });
        return nameError === "";
    }
    validateDesignation() {
        let designationError = "";
        const value = this.state.designation;
        if (value.trim() === "") designationError = "Designation is required";
        else if (value.length < 5)
            designationError = 'Designation must be at least 5 characters long!';
        this.setState({
            designationError
        });
        return designationError === "";
    }
    validateAdvType() {
        let advTypeError = "";
        const value = this.state.advType;
        if (value.trim() === "") advTypeError = "Adv type is required";
        else if (value.length < 5)
            advTypeError = 'Please select adv type';
        this.setState({
            advTypeError
        });
        return advTypeError === "";
    }
    validateEmailAddress() {
        let emailError = "";
        const value = this.state.advertiserEmail;
        if (value.trim === "") emailError = "Email is required";
        else if (!validEmailRegex.test(value))
            emailError = "Please Enter Email";
        this.setState({
            emailError
        });
        return emailError === "";
    }

    validatePhoneNumber() {
        let phoneError = "";
        const value = this.state.advertiserNumber;
        if (value.trim === "") phoneError = "Phone number is required";
        else if (!validPhoneNoRegex.test(value))
            phoneError =
                "Please enter valid phone number";
        this.setState({
            phoneError
        });
        return phoneError === "";
    }
    saveCampaign = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "campaignName": this.state.campaignName,
            "description": this.state.description,
            "type": this.state.type,
            "imageUrl": this.state.image,
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "campaignUrl": this.state.campaignUrl,
            "advType": parseInt(this.state.advType),
            "bannerImg": this.state.bannerImg,
            "campaignPaymentType": this.state.campaignPaymentType,
            "advertiserName": this.state.advertiserName,
            "advertiserEmail": this.state.advertiserEmail.toLowerCase(),
            "advertiserNumber": this.state.advertiserNumber,
            "designation": this.state.designation,
            "clicks": this.state.campaignPaymentType == "free" ? 0 : parseInt(this.state.clicks),
            "views": this.state.campaignPaymentType == "free" ? 100 : parseInt(this.state.views),
            "timingType": this.state.timingType
        }
        console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/saveCampaign', payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        redirect: true
                    })
                    // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    createCampaign = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "campaignName": this.state.campaignName,
            "imageUrl": this.state.image,
            "type": this.state.type,
            "advType": parseInt(this.state.advType),
            "bannerImg": this.state.bannerImg,
            "description": this.state.description,
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "campaignUrl": this.state.campaignUrl,
            "campaignPaymentType": this.state.campaignPaymentType,
            "advertiserName": this.state.advertiserName,
            "advertiserEmail": this.state.advertiserEmail,
            "advertiserNumber": this.state.advertiserNumber,
            "designation": this.state.designation,
            "clicks": this.state.campaignPaymentType == "free" ? 0 : parseInt(this.state.clicks),
            "views": this.state.campaignPaymentType == "free" ? 100 : parseInt(this.state.views),
            "timingType": this.state.timingType
        }
        console.log("payload", payload)
        var self = this
        // axios.post(config.userUrl + 'user/createCampaign', payload, { headers: headers })
        //     .then(function (response) {
        //         // console.log(response);
        //         if (response.data.status == true) {
        //             self.setState({
        //                 redirect: true
        //             })
        //             //console.log("success", response.data.message, payload);
        //         } else {
        //             console.log("Error", payload);
        //         }
        //     })
        //     .catch(function (error) {
        //         console.log("error", error, payload);
        //     });
    };
    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }
    uploadBannerImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }
    // handleImage = async (e) => {
    //     //console.log("fgh", e.target.files)
    //     if (e.target.files && e.target.files[0]) {
    //         const res = await this.uploadImage(e.target.files[0]);
    //         if (res) {
    //             //console.log("res", res)
    //             this.setState({ image: res })
    //         }
    //     }
    // }
    handleLogoImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            //  console.log('Selected file:', file);
            //  console.log('File size in bytes:', file.size, 64 * 1024, file.size > (64 * 1024));
            let size = ((file.size / 1024).toFixed(2));
            console.log('File type:', file.type);
            const maxSizeInKB = 64;
            if (file.size > maxSizeInKB * 1024) {
                enqueueSnackbar(`Image size is ${size} KB. It should be less than or equal to ${maxSizeInKB} KB.`, { variant: 'error' });
                return;
            }
            const res = await this.uploadImage(file);
            if (res) {
                this.setState({ image: res });
                console.log("res", res)
            }
        } else {
            //     console.warn('No file selected');
        }
    }
    handleBannerImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            let size = ((file.size / 1024).toFixed(2));
            const maxSizeInKB = 270;
            console.log('Selected file:', file);
            console.log('File size in bytes:', file.size, 64 * 1024, file.size > (64 * 1024));
            if (file.size > maxSizeInKB * 1024) {
                enqueueSnackbar(`Image size is ${size}. It should be less than or equal to ${maxSizeInKB} KB.`, { variant: 'error' });
                return;
            }
            const res = await this.uploadBannerImage(file);
            if (res) {
                this.setState({ bannerImg: res });
                console.log("resbanner", res)
            }
        } else {
            // console.warn('No file selected');
        }
    }
    render() {
        const { redirect, imgRef, image, bannerImg } = this.state;
        if (redirect) {
            return <Redirect to="/advertisement" />
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5rem", marginLeft: "275px" }} >
                    <Form onSubmit={this.handleSubmit}>

                        <Card style={{ width: "80%" }}>
                            <div style={{ marginLeft: "1.2rem", width: "75%" }}>
                                <h5 className="mt-2 font-heading-web">Create New Campaign</h5><br />

                                {/* <div>
                                    <div style={{ height: "60px", objectFit: "contain" }}>
                                        <label htmlFor="upload-button">
                                            {this.state.image.name ? (
                                                <img src={URL.createObjectURL(this.state.image)} alt="dummy" width="60" height="60" />
                                            ) : (
                                                <div style={{ display: "flex" }}>
                                                    <img src={addimage} style={{ width: "30%" }} />
                                                    <p className="ml-3 mt-4">Ad Image here</p>
                                                </div>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            id="upload-button"
                                            style={{ display: "none" }}
                                            onChange={this.fileHandler}
                                        />
                                    </div>
                                </div><br /> */}
                                <div class="row" >
                                    <div class="col-md-6">
                                        <label className="poll-date">Campaign Name<span className="red">*</span></label>
                                        <input type="text"
                                            className="web-font form-control"
                                            aria-describedby="text"
                                            name="campaignName"
                                            placeholder="Campaign Name"
                                            onChange={this.onChange}
                                            onBlur={this.handleBlur}
                                        />
                                        <div className="div">{this.state.campaignNameError && (
                                            <div className="error">{this.state.campaignNameError}</div>
                                        )}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <label className="poll-date">Adv Type<span className="red">*</span></label>
                                        <select name="advType" onChange={this.onChange} class="web-font form-control">
                                            <option value="">Select adv type</option>
                                            {this.state.advTypes.map(data =>
                                                <option value={data.id}>{data.advType}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                {/* <label className="poll-date">Campaign Name<span className="red">*</span></label>
                                <div className="form-group">
                                    <input type="text"
                                        className="web-font form-control"
                                        aria-describedby="text"
                                        name="campaignName"
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                    <div className="div">{this.state.campaignNameError && (
                                        <div className="error">{this.state.campaignNameError}</div>
                                    )}</div>
                                </div> */}
                                <div class="mt-4 row" >
                                    <div class="col-md-6">
                                        <label className="poll-date">Business Type<span className="red">*</span></label>
                                        <select name="type" onBlur={this.handleBlur} onChange={this.onChange} class="web-font form-control">
                                            <option value="">Select</option>
                                            <option value="commercial">Commercial</option>
                                            <option value="non-commercial">Non-Commercial</option>
                                        </select>
                                        <div className="div">{this.state.addError && (
                                            <div className="error">{this.state.addError}</div>
                                        )}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <label className="poll-date">Paid / Free<span className="red">*</span></label>
                                        <select name="campaignPaymentType" onChange={this.onChange} class="web-font form-control">
                                            <option value="">Select</option>
                                            <option value="paid">Paid</option>
                                            <option value="free">Free</option>
                                        </select>
                                    </div>
                                </div><br />
                                <label className="poll-date">Description<span className="red">*</span></label>
                                <div className="form-group">
                                    <textarea type="text"
                                        className="web-font form-control"
                                        aria-describedby="text"
                                        rows="5"
                                        name="description"
                                        placeholder="Description"
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                    <div className="div">{this.state.descriptionError && (
                                        <div className="error">{this.state.descriptionError}</div>
                                    )}</div>
                                </div><br />
                                <div class="row">
                                    <div class="col-md-6">
                                        <label className="poll-date">Start Date<span className="red">*</span></label>
                                        <div className="form-group">
                                            <DatePicker
                                                className="web-font form-control form-bg"
                                                selected={this.state.startDate}
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                onChange={(date) => this.changeStartDate(date)}
                                                filterDate={(date) => date.getDay() === 1}
                                                placeholderText="dd/mm/yyyy"
                                            />
                                            <div className="error">{this.state.dateErr}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label className="poll-date">End Date<span className="red">*</span></label>
                                        <div className="form-group">
                                            <DatePicker
                                                className="web-font form-control form-bg"
                                                selected={this.state.endDate}
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date(Date.now() + 5 * 86400000)}
                                                onChange={(date) => this.changeEndDate(date)}
                                                filterDate={(date) => date.getDay() === 5}
                                                placeholderText="dd/mm/yyyy"
                                            />
                                        </div>
                                    </div>
                                </div><br />
                                <div class="row">
                                    <div class="col-md-6">
                                        <label className="poll-date">URL<span className="red">*</span></label>
                                        <div className="form-group">
                                            <input type="text"
                                                className="web-font form-control"
                                                aria-describedby="text"
                                                placeholder="Campaign Url"
                                                name="campaignUrl"
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label className="poll-date">Timing Type<span className="red">*</span></label>
                                        <div className="form-group">
                                            <select
                                                name="timingType"
                                                className="form-control web-font"
                                                value={this.state.timingType}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select Timing type</option>
                                                <option value={"Omni time"}>Omni time</option>
                                                <option value={"Prime time"}>Prime time</option>
                                            </select>
                                        </div>
                                    </div>
                                </div><br />
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="poll-date">No.of Views<span className="red">*</span></label>
                                        <input type="text"
                                            name="views"
                                            className="form-control web-font"
                                            value={this.state.campaignPaymentType === "free" ? 100 : this.state.views}
                                            placeholder="No.of Views"
                                            style={{ marginRight: '5px' }}
                                            disabled={this.state.campaignPaymentType == "free" ? true : false}
                                            onChange={(e) => {
                                                const value = e.target.value;

                                                if (this.state.campaignPaymentType === "free") {
                                                    this.setState({ views: 100 });
                                                } else {
                                                    if (value === "") {
                                                        this.setState({ views: "" });
                                                    } else {
                                                        const parsedValue = parseInt(value, 10);
                                                        if (!isNaN(parsedValue) && parsedValue > 0) {
                                                            this.setState({ views: parsedValue });
                                                        }
                                                    }
                                                }
                                            }} />


                                    </div>
                                    <div className="col-md-6">
                                        <label className="poll-date">No.of Clicks<span className="red">*</span></label>
                                        <input
                                            type="text"
                                            name="clicks"
                                            className="form-control web-font"
                                            value={this.state.campaignPaymentType === "free" ? 0 : this.state.clicks}
                                            disabled={this.state.campaignPaymentType === "free"}
                                            placeholder="No. of Clicks"
                                            onChange={(e) => {
                                                const value = e.target.value;

                                                if (this.state.campaignPaymentType === "free") {
                                                    this.setState({ clicks: 0 });
                                                } else {
                                                    if (value === "") {
                                                        this.setState({ clicks: "" });
                                                    } else {
                                                        const parsedValue = parseInt(value, 10);
                                                        if (!isNaN(parsedValue) && parsedValue > 0) {
                                                            this.setState({ clicks: parsedValue });
                                                        }
                                                    }
                                                }
                                            }} />
                                    </div>
                                </div><br />
                                {/* <div className="form-group">
                                    <label className="poll-date">Select Image<span className="red">*</span></label><br />
                                    <img src={sceemimage} alt="" onClick={() => this.setState({ imageUrl: "uploads/1649942289609advertisement.png" })} className={this.state.imageUrl === "uploads/1649942289609advertisement.png" ? "border-url create-imageurl" : "create-imageurl"} />
                                </div> */}
                                <Row style={{ width: "100%" }}>
                                    <Col md={4}>
                                        <label className="poll-date">Upload Logo<span className="red">*</span></label><br />
                                        <div style={{ height: "120px", textAlign: "center", objectFit: "contain", border: "1px solid black", borderRadius: "10px" }}>
                                            <label htmlFor="upload-button-logo">
                                                {image != "" ? (
                                                    <img src={image} style={{ marginTop: "5px", marginBottom: "5px", width: "100px", height: "100px" }} />
                                                ) : (
                                                    <>
                                                        <img src={fileupload} style={{ marginTop: "2.5rem", cursor: "pointer", width: "29px" }} />

                                                    </>
                                                )}
                                            </label>
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                id="upload-button-logo"
                                                name="image"
                                                onChange={(e) => this.handleLogoImage(e)}
                                            />
                                        </div>
                                        <br />
                                    </Col>
                                    <Col md={8}>
                                        <label className="ml-1 mt-1 poll-date" style={{ fontSize: "14px" }}>Product/Promotion Banner<span className="red">*</span></label><br />
                                        <div style={{ height: "120px", textAlign: "center", objectFit: "contain", border: "1px solid black", borderRadius: "10px" }}>
                                            <label htmlFor="upload-button-banner">
                                                {bannerImg != "" ? (
                                                    <img src={bannerImg} style={{ marginTop: "4px", marginBottom: "4px", width: "240px", height: "110px" }} />
                                                ) : (
                                                    <>
                                                        <img src={fileupload} style={{ marginTop: "2.5rem", cursor: "pointer", width: "29px" }} />

                                                    </>
                                                )}
                                            </label>
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                id="upload-button-banner"
                                                name="bannerImg"
                                                onChange={(e) => this.handleBannerImage(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card><br />
                        <Card style={{ width: "80%" }}><br />
                            <div style={{ marginLeft: "1.2rem", width: "75%" }}>
                                <h5 >Advertiser's Details</h5><br />
                                <div class="row">
                                    <div class="col-md-6">
                                        <label className="poll-date">Advertiser Name<span className="red">*</span></label>
                                        <input type="text"
                                            className="web-font form-control"
                                            name="advertiserName"
                                            onChange={this.onChange}
                                            onBlur={this.handleBlur}
                                        />
                                        <div className="div">{this.state.nameError && (
                                            <div className="error">{this.state.nameError}</div>
                                        )}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="poll-date">Advertiser Designation<span className="red">*</span></label>
                                        <input type="text"
                                            className="web-font form-control"
                                            name="designation"
                                            onChange={this.onChange}
                                            onBlur={this.handleBlur}
                                        />
                                        <div className="div">{this.state.designationError && (
                                            <div className="error">{this.state.designationError}</div>
                                        )}</div>
                                    </div>
                                </div><br />
                                <div class="row">
                                    <div class="col-md-6">
                                        <label className="poll-date">Email<span className="red">*</span></label>
                                        <input type="email"
                                            className="web-font form-control"
                                            name="advertiserEmail"
                                            onChange={this.onChange}
                                            onBlur={this.handleBlur}
                                        />
                                        <div className="div">{this.state.emailError && (
                                            <div className="error">{this.state.emailError}</div>
                                        )}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <label className="poll-date">Mobile Number<span className="red">*</span></label>
                                        <input type="number"
                                            className="web-font form-control"
                                            name="advertiserNumber"
                                            onChange={this.onChange}
                                            onBlur={this.handleBlur}
                                        />
                                        <div className="div">{this.state.phoneError && (
                                            <div className="error">{this.state.phoneError}</div>
                                        )}</div>
                                    </div>
                                </div>
                            </div><br />
                        </Card><br /> {((this.state.campaignName !== "") && (this.state.image !== "") && (this.state.bannerImg !== "") && (this.state.clicks !== null && this.state.clicks !== undefined && this.state.clicks >= 0) &&
                            (this.state.views !== null && this.state.views !== undefined && this.state.views >= 0) && (this.state.type !== "") && (this.state.advType !== "") && (this.state.timingType !== "") && (this.state.campaignPaymentType !== "") && (this.state.campaignUrl !== "") && (this.state.startDate !== "") && (this.state.endDate !== "") && (this.state.description !== "") && (this.state.advertiserName !== "") && (this.state.advertiserEmail !== "") && (this.state.advertiserNumber !== "") && (this.state.designation !== "")) ?
                            // <div><Button onClick={this.createCampaign} variant="default" className="camp-next">Publish</Button><Button variant="default" onClick={this.saveCampaign} className="ml-2 camp-done">Save</Button></div> :
                            //  <div><Button onClick={this.createCampaign} variant="default" className="camp-next" disabled={true}>Publish</Button><Button variant="default" onClick={this.saveCampaign} disabled={true} className="ml-2 camp-done">Save</Button></div>}
                            <div className="cancel-right"><Button variant="default" onClick={this.createCampaign} className="sendreview-btn mt-1 ml-4">Submit</Button><Button onClick={this.saveCampaign} className="save-btn mt-1 ml-3" variant="default">Save</Button></div> :
                            <div className="cancel-right"><Button variant="default" onClick={this.createCampaign} disabled={true} className="sendreview-btn mt-1 ml-4">Submit</Button><Button onClick={this.saveCampaign} disabled={true} className="save-btn mt-1 ml-3" variant="default">Save</Button></div>}
                        <br />
                    </Form>
                </div >
            </div >
        );
    };
}

