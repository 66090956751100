import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import addimage from '../images/addimage.svg'
import './Advertisement.css';
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import fileupload from '../images/file-upload-svgrepo-com.svg';
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewUserCampaignById extends Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef()
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            imageUrl: "",
            image: "",
            campaignName: "",
            type: "",
            timingType: "",
            clicks: "",
            views: "",
            description: "",
            startDate: "",
            endDate: "",
            campaignUrl: "",
            campaignPaymentType: "",
            advertiserName: "",
            advertiserEmail: "",
            advertiserNumber: "",
            designation: "",
            location: "",
            url: "",
            advType: "",
            bannerImg: "",
            advTypes: [],
            selectedAdvType: '',
            paidOrFree: "",
            tireToAdvertise: "",
            payment: "",
            publish: true,
            save: false,
            isLoading: true

        }
    }
    componentDidMount = () => {
        this.getAllAdvertismentTypes();
        this.getCampaignById();
    }
    getAllAdvertismentTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllAdvertismentTypes', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({ advTypes: response.data.advTypes })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getCampaignById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var payload = {
            "advertisementId": this.props.match.params.id
        }
        var self = this;
        axios.post(config.userUrl + "user/getAdvertisementById", payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    //    console.log("success!!");
                    self.setState({
                        campaignName: response.data.advertisement[0].campaignName,
                        campaignPaymentType: response.data.advertisement[0].advertiserPaymentType,
                        description: response.data.advertisement[0].description,
                        startDate: response.data.advertisement[0].startDate,
                        endDate: response.data.advertisement[0].endDate,
                        image: response.data.advertisement[0].imageUrl,
                        type: response.data.advertisement[0].type,
                        campaignUrl: response.data.advertisement[0].campaignUrl,
                        advertiserName: response.data.advertisement[0].advertiserName,
                        advertiserEmail: response.data.advertisement[0].advertiserEmail,
                        advertiserNumber: response.data.advertisement[0].advertiserNumber,
                        designation: response.data.advertisement[0].designation,
                        advType: response.data.advertisement[0].advType,
                        selectedAdvType: response.data.advertisement[0].advType,
                        bannerImg: response.data.advertisement[0].bannerImg,
                        selectedAdvType: response.data.advertisement[0].advType,
                        timingType: response.data.advertisement[0].timingType,
                        clicks: response.data.advertisement[0].clicks,
                        views: response.data.advertisement[0].views,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { startDate, endDate, isLoading, image, imgRef, advTypes, bannerImg, selectedAdvType } = this.state
        var a = new Date(startDate)
        var start = parseInt(a.getMonth() + 1) + "-" + a.getDate() + "-" + a.getFullYear();
        var b = new Date(endDate)
        var endD = parseInt(b.getMonth() + 1) + "-" + b.getDate() + "-" + b.getFullYear();
        const getText = (id) => {
            const item = advTypes.find(txt => txt.id === id);
            return item ? item.advType : "";
        }
        const selectedStartDate = new Date(start)
        const selectedEndDate = new Date(endD)
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <Card style={{ width: "80%" }}><br />
                        <h5 style={{ marginLeft: "1.2rem" }}>View Campaign</h5><br />
                        <Form style={{ marginLeft: "1.2rem", width: "70%" }}>
                            <div class="row" >
                                <div class="col-md-6">
                                    <label className="poll-date">Campaign Name<span className="red">*</span></label>
                                    <input type="text"
                                        className="web-font form-control"
                                        aria-describedby="text"
                                        name="campaignName"
                                        value={this.state.campaignName}
                                        readOnly
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Adv Type<span className="red">*</span></label>
                                    <select name="advType" readOnly className="web-font form-control">
                                        <option value={this.state.selectedAdvType ? this.state.selectedAdvType : ""}>{this.state.selectedAdvType ? getText(this.state.selectedAdvType) : "Select adv type"}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row" >
                                <div class="col-md-6">
                                    <label className="poll-date">Business Type<span className="red">*</span></label>
                                    <select readOnly value={this.state.type} name="type" class="web-font form-control">
                                        <option value="">Select</option>
                                        <option value="commercial">Commercial</option>
                                        <option value="non-commercial">Non-Commercial</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Paid / Free</label>
                                    <select readOnly value={this.state.campaignPaymentType} name="campaignPaymentType" class="web-font form-control">
                                        <option value="">Select</option>
                                        <option value="paid">Paid</option>
                                        <option value="free">Free</option>
                                    </select>
                                </div>
                            </div><br />
                            <label className="poll-date">Description<span className="red">*</span></label>
                            <div className="form-group">
                                <textarea type="text"
                                    className="web-font form-control"
                                    aria-describedby="text"
                                    rows="5"
                                    name="description"
                                    readOnly
                                    value={this.state.description}
                                />
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Start Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedStartDate}
                                            minDate={new Date(Date.now())}
                                            dateFormat="dd-MM-yyyy"
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">End Date<span className="red">*</span></label>
                                    <div className="form-group">
                                        <DatePicker
                                            className="web-font form-control form-bg"
                                            selected={selectedEndDate}
                                            readOnly
                                            minDate={new Date(selectedStartDate + 5 * 86400000)}
                                            dateFormat="dd-MM-yyyy"
                                            filterDate={(date) => date.getDay() === 5}
                                        />
                                    </div>
                                </div>
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">URL<span className="red">*</span></label>
                                    <div className="form-group">
                                        <input type="text"
                                            className="web-font form-control"
                                            aria-describedby="text"
                                            placeholder="Campaign Url"
                                            name="campaignUrl"
                                            value={this.state.campaignUrl}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Timing Type<span className="red">*</span></label>
                                    <div className="form-group">
                                        <select
                                            name="timingType"
                                            className="form-control web-font"
                                            value={this.state.timingType}
                                            readOnly
                                        >
                                            <option value="">Select Timing type</option>
                                            <option value={"Omni time"}>Omni time</option>
                                            <option value={"Prime time"}>Prime time</option>
                                        </select>
                                    </div>
                                </div>
                            </div><br />
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="poll-date">No.of Views<span className="red">*</span></label>
                                    <input type="text"
                                        name="views"
                                        className="form-control web-font"
                                        value={this.state.campaignPaymentType == "free" ? 100 : this.state.views}
                                        placeholder="No.of Views"
                                        style={{ marginRight: '5px' }}
                                        disabled={this.state.campaignPaymentType == "free" ? true : false}
                                        readOnly />


                                </div>
                                <div className="col-md-6">
                                    <label className="poll-date">No.of Clicks<span className="red">*</span></label>
                                    <input type="text"
                                        name="clicks"
                                        className="form-control web-font"
                                        value={this.state.campaignPaymentType == "free" ? 0 : this.state.clicks}
                                        disabled={this.state.campaignPaymentType == "free" ? true : false}
                                        placeholder="No.of Clicks"
                                        readOnly />
                                </div>
                            </div><br />
                            <div className="form-group">
                                <Row style={{ width: "100%" }}>
                                    <Col md={4}>
                                        <label className="poll-date">Upload Logo<span className="red">*</span></label><br />
                                        <div style={{ height: "120px", textAlign: "center", objectFit: "contain", border: "1px solid black", borderRadius: "10px" }}>
                                            <label htmlFor="upload-button-logo">
                                                {image != "" ? (
                                                    <img src={image} style={{ marginTop: "5px", marginBottom: "5px", width: "100px", height: "100px" }} />
                                                ) : (
                                                    <>
                                                        <img src={fileupload} style={{ marginTop: "2.5rem", cursor: "pointer", width: "29px" }} />

                                                    </>
                                                )}
                                            </label>
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                id="upload-button-logo"
                                                name="image"
                                            />
                                        </div>
                                        <br />
                                    </Col>
                                    <Col md={8}>
                                        <label className="ml-1 mt-1 poll-date" style={{ fontSize: "14px" }}>Product/Promotion Banner<span className="red">*</span></label><br />
                                        <div style={{ height: "120px", textAlign: "center", objectFit: "contain", border: "1px solid black", borderRadius: "10px" }}>
                                            <label htmlFor="upload-button-banner">
                                                {bannerImg != "" ? (
                                                    <img src={bannerImg} style={{ marginTop: "4px", marginBottom: "4px", width: "240px", height: "110px" }} />
                                                ) : (
                                                    <>
                                                        <img src={fileupload} style={{ marginTop: "2.5rem", cursor: "pointer", width: "29px" }} />

                                                    </>
                                                )}
                                            </label>
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                id="upload-button-banner"
                                                name="bannerImg"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form><br />
                    </Card><br />
                    <Card style={{ width: "80%" }}><br />
                        <div style={{ marginLeft: "1.2rem", width: "75%" }}>
                            <h5 >Advertiser's Details</h5><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Advertiser Name<span className="red">*</span></label>
                                    <input type="text"
                                        className="web-font form-control"
                                        name="advertiserName"
                                        value={this.state.advertiserName}
                                        readOnly
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="poll-date">Advertiser Designation<span className="red">*</span></label>
                                    <input type="text"
                                        className="web-font form-control"
                                        name="designation"
                                        value={this.state.designation}
                                        readOnly
                                    />
                                </div>
                            </div><br />
                            <div class="row">
                                <div class="col-md-6">
                                    <label className="poll-date">Email<span className="red">*</span></label>
                                    <input type="email"
                                        className="web-font form-control"
                                        name="advertiserEmail"
                                        readOnly
                                        value={this.state.advertiserEmail}
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label className="poll-date">Mobile Number<span className="red">*</span></label>
                                    <input type="number"
                                        className="web-font form-control"
                                        name="advertiserNumber"
                                        readOnly
                                        value={this.state.advertiserNumber}
                                    />
                                </div>
                            </div>
                        </div><br />
                    </Card><br />
                    <Link to="/advertisement"><div className="cancel-right"><Button variant="default" className="cancelreview-btn mt-1 ml-4">Cancel</Button>
                    </div></Link>
                </div>
            </div>
        );
    };
}

